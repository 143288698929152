import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "BANGRS3",
  "date": "2021-12-16"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`BANGRS3`}</h1>
    <h2>{`Summary`}</h2>
    <p>{`one of 7 discs mostly randomly generated from
`}<a parentName="p" {...{
        "href": "https://twitter.com/ringtonebangers"
      }}>{`Ringtone Bangers`}</a>{`
clips as they have uploaded to google drive. track layout determined via
script. recorded in SP MONO.`}</p>
    <p>{`the first track was hardwired to be the classic Nokia tone.`}</p>
    <p>{`the intent of this disc was to push a minidisc session to the limits for
track capacity and title naming. at 254 tracks, that gives each track seven
characters to hold the track name. track 0 is actually the free block map,
and the disc title...`}</p>
    <p><em parentName="p">{`this disc`}</em>{` has one exception! track 254 is
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=I3Ak5VgyEoc"
      }}>{`the microsoft sound`}</a>{`, as snagged
from a windows 2000 install. this was due to the minidisc compiling script
probably having a bug and not adding one more track. oops.`}</p>
    <h2>{`Tracks`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=pe1ZXh5_wk4"
        }}><inlineCode parentName="a">{`INGTONE`}</inlineCode>{`	Nokia 3310 - Classic Monophonic RINGTONE (2017).mp4`}</a></li>
      <li parentName="ol"><inlineCode parentName="li">{`NMYSOUL`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/157n Sony Ericsson T700 - In my soul.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ANORIFF`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/238d iPhone - Piano Riff.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LSIGNAL`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/36 Motorola W490 - Digital Signal.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EPOLISH`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/265d HTC Ozone - Polish.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RBREATH`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/212d Samsung Ego - Hold your breath.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ALIENTE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/75 Windows Mobile (downloadable) - Ballroom Caliente.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SEDRIVE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/335d fusoxide_s Ringtones Vol. 1 - House Drive.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`UNSHINE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/368d Samsung Galaxy Note 7 - Sunshine.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LAWLESS`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/332n Motorola Q9h - Flawless.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ASTPACE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/155d Motorola Q9m - Fast Pace.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`REAMYOU`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/356d Samsung SGH-i700 - Dream You.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IMETREE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/90 Nokia 6680 - Lime tree.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ERIMENT`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/347n Nokia N9 - Noise experiment.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IGHTOWL`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/175n iOS 7 - Night Owl.m4r`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HETRICK`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/255d Nokia 6500 - The trick.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INLOOPS`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/74 Motorola RAZR V3c - Latin Loops.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DEMO055`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/282d Yamaha SMAF (downloadable) - MA Sound Demo055.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0NUANCE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/97n Nokia 6270 - Nuance.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RGOTCHA`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/219d Android 2.0 Eclair - Gotcha.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LDSTYLE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/96n Motorola RIZR Z3 - Wild Style.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`370CLUB`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/136d Nokia 7370 - Club.3gp`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ERSURGE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/134n Motorola E1070 - Power Surge.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`STARTUP`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380dg Ubuntu 4.10 - Startup.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`3XPERIA`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/91 Sony Xperia Z3 - xperia.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ENDANCE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/317n HP iPAQ Glisten - Dance.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CENSION`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/27 Motorola RIZR Z3 - Ascension.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ARNIVAL`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/284d Samsung Galaxy S20 - Carnival.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0ILATIN`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/92d Sony Ericsson K800i - Latin.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`KINGDOM`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/184d Panasonic G60 - 808Kingdom.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`E10AQUA`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/213d HTC Sense 1.0 - Aqua.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`90BIKER`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/119d Motorola C390 - Biker.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ETSTYLE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380n Motorola W490 - Street Style.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SYBREAK`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/267d HTC E-Club Ring Tone Pack 2 - Bluesy Break.MP3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DEMO041`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/190n Yamaha SMAF (downloadable) - MA Sound Demo041.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0BRUNCH`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/208n Sony Ericsson T700 - Brunch.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RASONIC`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/83 Motorola E398 - Ultra Sonic.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EGROOVE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/105n Nokia 7270 - Re-groove.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CSLEAZY`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/10 Motorola E1000 - Mcsleazy.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`THEEDGE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/246d Motorola E1070 - Off The Edge.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`REDIBLE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/280d Nokia 6270 - Elecredible.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`00BELL6`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/50 Samsung SGH-X100 - Bell 6.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ESSENCE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/248n Motorola V551 - Essence.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`H1OHRID`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/31 Essential PH-1 - Ohrid.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TELLITE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/94n Samsung Galaxy S10 - Satellite.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`START~1`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380da KDE 3.0 - Startup.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`USCIOUS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/188n Motorola W490 - Luscious.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IGSOLAR`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/182d Motorola M702ig - Solar.3gp`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`FASHION`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/104n Motorola RAZR V3i - Fashion.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`WFLAKES`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/113d Windows Mobile Ring Tone Pack 3 - Snow Flakes.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CATWALK`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/184n Nokia 5300 - Catwalk.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TARTUP2`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380dc KDE 3.3 - Startup 2.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RADIATE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/149n iOS 7 - Radiate.m4r`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NRAMBLE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/331d Alcatel OT-660 - Urban Ramble.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`T_TTONE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/130n AT_T - AT_T Tone.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DOSCOPE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/92n Motorola E365 - Kaleidoscope.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ELSGOOD`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/320n Samsung Galaxy S20 - Feels Good.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OVIOLET`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/271d Nokia Oro - Violet.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ALKAWAY`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/258d Nokia N85 - Walk away.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HOLIDAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/116n Samsung Galaxy S8 - Holiday.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NFORMER`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/247n HTC One M9 - Informer.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ANDMILK`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/112d Windows Mobile Ring Tone Pack 8 - Cookies And Milk.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`PDRAGON`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/165d Nokia 7710 - Snapdragon.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EBOTTLE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/153n Samsung Galaxy Chat - Wine bottle.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`USRETRO`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/378d Vestel Venus - Retro.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CEPTRUM`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/265n Android 2.3 Gingerbread - Sceptrum.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ILKYWAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/180n Android 2.0 Eclair - Silky Way.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EFLIGHT`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/136n Android 2.0 Eclair - Free Flight.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TICTONE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/28 Samsung Galaxy S5 - Mystic Tone.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0AMINOR`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/197n LG S5100 - A minor.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LATINUM`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/325d Android 4.0 Ice Cream Sandwich - Platinum.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`THEBEAT`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/117d Windows Mobile Ring Tone Pack 8 - Carol Of The Beat.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`BEANICE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/101d Android 1.0 - Caribbean Ice.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CEPARTY`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/72 Samsung Galaxy S6 - Dance Party.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RL7FIRE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/100d Motorola SLVR L7 - Fire.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OULMATE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/351n Samsung SGH-D980 DUOS - Melody5 (Soul mate).wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CLAPPER`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/346d Nokia N78 - Clapper.mp4`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LATIN~1`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/272d Motorola Q9m - Platinum.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SEVILLE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/226d Android 1.5 Cupcake - Seville.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`KYSHORT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/251n Motorola RAZR V3 - Spooky (short).flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`UNLEASH`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/110d Motorola Q9c - Unleash.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LKCYCLE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/341d Maxo Phone Tones Pack #5 - Walk Cycle.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`WFLAK~1`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/115d Nokia 6681 - Snowflakes.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`AINLINK`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/294d Motorola V600 - Chain Link.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GETAWAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/161d Google Pixel 4 - Getaway.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OCKSOLO`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/364d Motorola W180 - Rock Solo.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`70TWIRL`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/150n Motorola E1070 - Twirl.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OCKTAIL`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/142n Motorola RAZR V3i - Cocktail.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TORYLAP`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/326n Google Sounds 2.2 - Victory Lap.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`6LIQUID`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/183n Motorola SLVR L6 - Liquid.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HRIDING`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/288n Samsung Ego - Smooth riding.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ANDWINE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/108n LG KG280 - Cigar And Wine.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HANGING`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/315d Motorola A3100 - Hanging.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ORSOLAR`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/155n Nokia 9500 Communicator - Solar.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GTONE01`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/42 Windows 7 - Ringtone 01.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GTONE17`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/277n LG enV2 - Ringtone 17.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ENALINE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/195d Motorola KRZR K1 - Adrenaline.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NVASION`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/330d Motorola Droid A855 - Droid Invasion.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INGTO~1`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/266n Nokia Audio themes - Jazz ringtone.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NEWFLOW`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/14 Motorola RAZR V3i - New Flow.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0GROOVE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/149d Motorola A1000 - Groove.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TEPPERS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/246n Panasonic G50 - Steppers.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`START~2`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380df Mandriva Linux 2007 - Startup.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SODAPOP`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/248d Nokia 6300 (newer firmware) - Soda pop.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LLBLEND`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/152d Android 1.0 - Curve Ball Blend.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RALPARK`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/224d LG Sentio - Central Park.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INGTO~2`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/256d Nokia Audio themes - Car ringtone.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GHTCLUB`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/262n Motorola ROKR Z6 - Nightclub.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ISLANDS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/268n Motorola A920 - The Islands.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SOLARIS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/187d HTC Ozone - Solaris.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`60GREEN`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/313d Alcatel OT-660 - Green.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`YS8AQUA`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/198n Samsung Galaxy S8 - Aqua.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GHSCORE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/151d Windows Mobile Extended Audio Pack 1 - High Score.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IGHTIDE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/373d Samsung Galaxy S III - High tide.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DEMO044`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/244d Yamaha SMAF (downloadable) - MA Sound Demo044.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LEFEVER`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/355d LG KG800 Chocolate - Jungle Fever.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ILLASKY`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/54 LG KG240 - Vanilla Sky.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ERWORLD`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/209d Samsung Galaxy S III - Underwater world.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ITRAILS`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/378n Nokia 5140i - Trails.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`2COVERT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/219n HTC HD2 - Covert.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ROHOUSE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/296d Samsung PC Studio - Euro House.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OVATION`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/127d HTC E-Club Ring Tone Pack 1 - HTC Innovation.MP3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`BYWALTZ`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/206n3 Samsung Galaxy S20 - Baby Waltz.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`THATWAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/145n Nokia N73 - Stay that way.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`1ORANGE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/67 Xiaomi Mi A1 - Orange.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RO2STEP`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/284n HTC Hero - 2 Step.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LLYDOIT`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/109n BenQ-Siemens S68 - You really do it.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0CIRCUS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/188d Motorola RIZR Z10 - Circus.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EAKDOWN`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/377n Motorola RAZR V3i - Breakdown.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ICDISCO`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/390d Microsoft Office clipart - Generic Disco.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OLAMOTO`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/127n Motorola - Moto.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RAINBOW`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/192n Nokia 113 - Triple rainbow.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ILOUNGE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/211n Motorola RAZR V3i - Lounge.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`QBOOGIE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/383n Windows Mobile (downloadable) - BBQ Boogie.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`BERBAND`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/38 Windows Mobile (downloadable) - Rubber Band.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0PLANET`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/339n Samsung Galaxy S10 - Planet.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SIONMA3`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/374d Panasonic X300 - Fusion ma-3.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ITCOMES`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/268d Samsung SGH-i400 - Here it comes.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`6CANVAS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/243n Nokia E66 - Canvas.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`5ROCKME`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/235n Motorola ROKR EM35 - Rock Me.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GFUSION`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/209n Motorola M702ig - Fusion.3gp`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0SOREAL`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/245n LG KG280 - So Real.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IHIGHER`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/348d Sony Ericsson K750i - Higher.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TTAHAVE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/294n Nokia 7370 - Gotta have.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LOWFISH`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/272n LG KG240 - Blowfish.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`PLUCKER`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/123n Android 1.0 - Beat Plucker.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`3STREET`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/227n Maxo PHONE TONES PACK #3 - Street.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`6WAHWAH`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/287n Nokia 2626 - Wah wah.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HARDMIX`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/288d Windows Mobile Ring Tone Pack 5 - Hard Mix.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HTSWING`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/365n Samsung Galaxy S9 - Midnight Swing.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DWESTST`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/287d Panasonic G60 - JCD West St..wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CHRONOS`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/53 Motorola A1000 - Chronos.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LIDSODA`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/107d Yamaha SMAF (downloadable) - Solid Soda.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`8COFFEE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/236n Samsung Galaxy S8 - Coffee.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NKY_ALL`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/302d Android 1.5 Cupcake - Funk Y_all.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ISTFLOW`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/348n Samsung Ch@t 335 - Moist flow.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0GLOWHQ`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/176d Nokia 7380 - Glow (HQ).wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HICBABA`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/312n Samsung Galaxy Pocket - Chic baba.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`UNNYDAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/103d Samsung C5212 - Sunny day.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0DAMMAR`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/327n HTC 10 - Dammar.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LESTONE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/80 HTC Sense 1.0 - Cobblestone.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EADNEON`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/79 Android 2.3 Gingerbread - Neon.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CECRAFT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/221n Samsung Galaxy S7 - Spacecraft.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NCOFIRE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/158d Windows Mobile Ring Tone Pack 2 - Flamenco Fire.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OUNGING`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/276d Nokia 7370 - Lounging.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RELAXED`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/343d Sony Ericsson G705 - Relaxed.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DREAMER`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/162d HTC 8-Bit Sound Set - Cloud Dreamer.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INGTO~3`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/251d Nokia Audio themes - Car videoringtone.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`URNMEON`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/173n LG Lotus - Turn Me On.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TARTUP1`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380db KDE 3.3 - Startup 1.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TJINGLE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/62 T-Jingle (2006).mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IDHENRY`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/344n Gionee GN305 - Horrid Henry.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OOKBACK`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/185n Samsung B5702 DuoS - Look Back.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CHHOUSE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/99d Sony Ericsson T700 - Beach house.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0LOUNGE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/281d Motorola A1000 - Lounge.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`START~3`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380dh Ubuntu 6.10 - Startup.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ASTWARD`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/343n Alcatel OT-708X - Eastward.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ISHLINE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/315n Google Sounds 2.2 - Finish Line.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ONDDAWN`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/322d HTC Touch Diamond - Dawn.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ECLOUDS`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/310d Sony Ericsson P1i - Into the clouds.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RETLIFE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/166d Samsung SGH-F480 Tocco - Secret Life.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Z3TEMPO`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/201d Motorola RIZR Z3 - Tempo.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IRDLOOP`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/164n Android 1.0 - Bird Loop.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`310KICK`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/340d Nokia 3310 - Kick.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TERNOON`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/135n LG KG320 - Lazy Afternoon.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`5MINGLE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/125d Nokia N85 - Mingle.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NODANCE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/192d Samsung SGH-P250 - Techno dance.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TENIGHT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/203n Sony Ericsson T700 - Late night.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0VISION`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/233d Windows Mobile 6.0 - Vision.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LITHAZE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/338d Motorola E815 - Moonlit Haze.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LAQRIFF`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/342d Motorola Q - Riff.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`5RUNWAY`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/350d Nokia N85 - Runway.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Q9HGLOW`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/357n Motorola Q9h - Glow.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`KCHIMER`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/71 Windows Mobile Ring Tone Pack - Chimer.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ONLIGHT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/207n HTC Droid DNA - Moon Light.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IGHTWAY`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/263n Yamaha SMAF (downloadable) - Straight Way.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EDITION`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/195n Android 1.5 Cupcake - Champagne Edition.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INGTO~4`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/319d Nokia Audio themes - Golf ringtone.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OPENING`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/129n iOS 7 - Opening.m4r`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`1RISING`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/204d Windows Mobile Extended Audio Pack 1 - Rising.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NEALARM`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/328n iPhone - Alarm.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SAFFRON`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/154d HTC Ozone - Saffron.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RE2KICK`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/359n Motorola ROKR E2 - Kick.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EFRIDAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/144d HTC Ozone - Friday.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CLOUNGE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/316n HTC Magic - Lounge.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OCOLATE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/115n Windows Mobile Ring Tone Pack 3 - Hot Chocolate.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ECIRCUS`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/362n Motorola A3100 - Space Circus.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`2RHODES`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/95n HTC E-Club Ring Tone Pack 2 - Rhodes.MP3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`AMBIENT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/292n Motorola RAZR V3i - Ambient.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`_SIGNAL`}</inlineCode>{`	Season 3 (days 182-300`}<em parentName="li">{` March 2021-July 2021)/199n Kyocera Kona - Poppin`}</em>{` Signal.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`10GROWL`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/107n Android 1.0 - Growl.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ICKITUP`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/163n Samsung Galaxy S9 - Pick It Up.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ACKDIVE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/230n Samsung Jack - Dive.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ORGANIC`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/191d HTC Touch - Organic.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`REVERIE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/145d Samsung Ego - Reverie.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SCRAPER`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/98n Samsung Galaxy S8 - Skyscraper.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`95GLINT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/216n Nokia N95 - Glint.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INGWALK`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/302n LG Lotus - Evening Walk.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`AYNIGHT`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/152n Motorola E398 - Saturday night.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`FUNTIME`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/339d LG CE110 - Fun-Time.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GONAVIS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/202n Android 2.3 Gingerbread - Argo Navis.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INAMASK`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/367d Motorola V191 (China) - Mask.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LSTREET`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/332d Sony Ericsson P1i - Hill street.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TRIPPER`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/387n HTC HD7 - Tripper.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EANKUMA`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/297n Android 4.2 Jelly Bean - Kuma.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`THEDARK`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/358n Samsung SGH-Z130 - Dancing in the Dark.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ACKHOLE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/387d Nokia Tones - Black Hole.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`97BEATS`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/33 Motorola V197 - Beats.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OLAQDOT`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/385n Motorola Q - Dot.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OS7SILK`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/76 iOS 7 - Silk.m4r`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DLEAVES`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/235d HTC Touch HD - Leaves.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`80IDAWN`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/48 Sony Ericsson W380i - Dawn.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`BOOKEM_`}</inlineCode>{`	Season 2 (days 92-181`}<em parentName="li">{` December 2020-March 2021)/143n HTC HD7 - Book Em`}</em>{`.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OGREMIX`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/273d Jamster - Crazy Frog Remix.mpg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`AYDREAM`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/262d Windows Mobile Ring Tone Pack 5 - Day Dream.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`90WAVES`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/135d Motorola C390 - Waves.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`JUPITER`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/270d Alcatel OT-660 - Jupiter.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`98FLUID`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/260d Motorola E398 - Fluid.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SURGENT`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/354n Sonic Logos, Links and Beds (Album 2) - It_s Urgent.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TIMEPAD`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/358d Alcatel OT-660 - Time Pad.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DESTINY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/111n Nokia 6681 - Destiny.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DEMO043`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/221d Yamaha SMAF (downloadable) - MA Sound Demo043.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CHINESE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/206n1 Nokia Tune Remake Audiodraft Sound Design Contest - NOKIA CHINESE.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`START~4`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380de Mandriva Linux 2006 - Startup.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`VOLTAGE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/59 BenQ-Siemens S68 - High Voltage.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HESTAGE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/158n Samsung Galaxy S6 - On the Stage.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NGTONE7`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/23 Windows 7 Beta - Ringtone 7.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NIVERSE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/45 Motorola SLVR L6g (Chinese version) - Little Universe.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ROOVING`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/227d Motorola M702ig - Grooving.3gp`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`FORWARD`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/303n T-Mobile Shadow - Moving Forward.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DAWNING`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/37 Nokia 8800 Sirocco Edition - Dawning.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`YLOUNGE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/213n Android 1.0 - Loopy Lounge.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SCOVERY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/148n Nokia 8600 - Discovery.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`MSSOUND`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=I3Ak5VgyEoc"
        }}>{`The Microsoft Sound`}</a></li>
    </ol>
    <h2>{`Mixing/Sourcing Notes`}</h2>
    <p>{`source to the script is available `}<a parentName="p" {...{
        "href": "https://gist.github.com/arrjay/3837fb1eca6d87f61d54741f9cf93a8d"
      }}>{`HERE`}</a>{`.
no promises it will run anywhere or is fit for any purpose.`}</p>
    <p>{`ringtone bangers archives are available via google drive, as linked in
`}<a parentName="p" {...{
        "href": "https://twitter.com/ringtonebangers/status/1444785594941550596"
      }}>{`their tweet`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      